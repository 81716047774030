'use strict';

const { trapFocus } = require('../components/util');

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#quickViewModal').length !== 0) {
        $('#quickViewModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="quickViewModal" role="dialog">'
        + '<div class="modal-dialog quick-view-dialog container">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * @typedef {Object} QuickViewHtml
 * @property {string} body - Main Quick View body
 * @property {string} footer - Quick View footer content
 */

/**
 * Parse HTML code in Ajax response
 *
 * @param {string} html - Rendered HTML from quickview template
 * @return {QuickViewHtml} - QuickView content components
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}


function animateCarousel() {
    var sync1 = $("#sync1");
    var sync2 = $("#sync2");
    var slidesPerPage = 4; //globaly define number of elements per page
    var syncedSecondary = true;

    sync1
        .owlCarousel({
            items: 1,
            slideSpeed: 2000,
            nav: true,
            autoplay: false,
            dots: false,
            loop: false,
            center: false,
            itemElement: 'li',
            stageElement: 'ul',
            responsiveRefreshRate: 200,
            navText: [
                '<span class="icon-CHEVRON" aria-hidden="true"></span><span class="sr-only">Previous panel</span>',
                '<span class="icon-CHEVRON" aria-hidden="true"></span><span class="sr-only">Next panel</span>',
            ],
        })
        .on('changed.owl.carousel', onChange);

    sync2
        .on('initialized.owl.carousel', function () {
            sync2.find(".owl-item").eq(0).addClass("current");
        })
        .owlCarousel({
            items: slidesPerPage,
            dots: false,
            nav: true,
            margin: 10,
            itemElement: "li",
            stageElement: "ul",
            slideBy: slidesPerPage, //alternatively you can slide by 1, this way the active slide will stick to the first item in the second carousel
            responsiveRefreshRate: 100,
            navText: [
                '<span class="icon-CHEVRON" aria-hidden="true"></span><span class="sr-only">Previous panel</span>',
                '<span class="icon-CHEVRON" aria-hidden="true"></span><span class="sr-only">Next panel</span>'
            ]
        }).on('changed.owl.carousel', syncPosition2);

    function onChange(el) {
        syncPosition(el);

        var disabledElements = $('.owl-prev.disabled, .owl-next.disabled');
        var enabledElements = $(
            '.owl-prev:not(.disabled), .owl-next:not(.disabled)'
        );

        disabledElements.attr('tabindex', -1);
        enabledElements.attr('tabindex', 0);
    }
    function syncPosition(el) {
        //if you set loop to false, you have to restore this next line
        var current = el.item.index;

        sync2
            .find(".owl-item")
            .removeClass("current")
            .eq(current)
            .addClass("current");
        var onscreen = sync2.find('.owl-item.active').length - 1;
        var start = sync2.find('.owl-item.active').first().index();
        var end = sync2.find('.owl-item.active').last().index();

        if (current > end) {
            sync2.data('owl.carousel').to(current, 100, true);
        }
        if (current < start) {
            sync2.data('owl.carousel').to(current - onscreen, 100, true);
        }
    }

    function syncPosition2(el) {
        if (syncedSecondary) {
            var number = el.item.index;
            sync1.data('owl.carousel').to(number, 100, true);
        }
    }

    sync2.on("click", ".owl-item", function (e) {
        e.preventDefault();
        var number = $(this).index();
        sync1.data('owl.carousel').to(number, 300, true);
    });


    $('.owl-prev').each(function () {
        var previousPanel = $('.previous-arrow-carousel').text();
        $(this).removeAttr('role');
        $(this).find('.sr-only').replaceWith("<span class='sr-only'>" + previousPanel + "</span>");

        if ($(this).hasClass('disabled')) {
            $(this).attr('tabindex', '-1')
        }
    });

    $('.owl-next').each(function () {
        var nextPanel = $('.next-arrow-carousel').text();
        $(this).removeAttr('role');
        $(this).find('.sr-only').replaceWith("<span class='sr-only'>" + nextPanel + "</span>");
    });

    $('.owl-next, .owl-prev').click(function(){
        if($(this).hasClass('owl-prev') && $(this).hasClass('disabled')){
            $('.overlay-left').addClass('overlay-right').removeClass('overlay-left');
        }
        if($(this).hasClass('owl-next') && $(this).hasClass('disabled')){
            $('.overlay-right').addClass('overlay-left').removeClass('overlay-right');
        }
    });
}

function quantitySelector() {
    $('#quantity-plus , #quantity-minus').on('click', function () {
        var newVal, oldValue, maxValue;
        var pid = ($(this).attr("data-pid"));
        var traductionText = $(this).parent('.pdp-qty-selector-content').find('#dynamic_update').data('text');
    
        if (!pid) {
            oldValue = $("#quantity option:selected").text();
            maxValue = parseInt($('#quantity option:last').val());
    
            if (this.id == "quantity-plus") {
                newVal = parseFloat(oldValue) + 1;
                $("#quantity option[value='"+newVal+"']").prop('selected', true);
                $("#quantity option[value='"+newVal+"']").text(newVal);
                $('#quantity-minus').prop('disabled', false);
                if (oldValue >= maxValue - 1) {
                    $('#quantity-plus').prop('disabled', true);
                }
            } else {
                if (oldValue > 0) {
                    newVal = parseFloat(oldValue) - 1;
                    $("#quantity option[value='"+newVal+"']").prop('selected', true);
                    $("#quantity option[value='"+newVal+"']").text(newVal);
                    $('#quantity-plus').prop('disabled', false);
                    if (oldValue <= 2) {
                        $('#quantity-minus').prop('disabled', true);
                    }
                } else {
                    newVal = 0;
                }
            }
            $(this).parent('.pdp-qty-selector-content').find('#dynamic_update').text(traductionText + ' ' + newVal);
        } else {
            pid = ($(this).attr("data-pid"));
            oldValue = parseInt($('.quantity[data-pid='+pid+'] option:selected').text());
            maxValue = parseInt($('.quantity[data-pid='+pid+'] option:last').val());
    
            if (this.id === 'quantity-plus') {
                newVal = parseFloat(oldValue) + 1;
                $(".quantity[data-pid="+pid+"] option[value="+newVal+"]").prop('selected', true);
                $(".quantity[data-pid="+pid+"] option[value="+newVal+"]").val(newVal).change();
                $('#quantity-minus[data-pid='+pid+']').prop('disabled', false);
                if (oldValue >= maxValue) {
                    $('#quantity-plus[data-pid='+pid+']').prop('disabled', true);
                }
            } else {
                if (oldValue > 0) {
                    newVal = parseFloat(oldValue) - 1;
                    $(".quantity[data-pid="+pid+"] option[value="+newVal+"]").prop('selected', true);
                    $(".quantity[data-pid="+pid+"] option[value="+newVal+"]").val(newVal).change();
                    $('#quantity-plus[data-pid='+pid+']').prop('disabled', false);
                    if (oldValue <= 2) {
                        $('#quantity-minus[data-pid='+pid+']').prop('disabled', true);
                    }
                } else {
                    newVal = 0;
                }
            }
            $(this).parent('.pdp-qty-selector-content').find('#dynamic_update').text(traductionText + ' ' + newVal);
        }
    });
}

function shadeSelector() {
    // Order by full & default shade position to have the same display on load & after show/more less
    orderByFullShadePosition();
    orderByDefaultShadePosition();
    var windowSize = $(window).width();
    var numberOfElementsToShow = 12;
    var numberOfElements = parseInt($(".color-swatchs-palette-container li").length);
    if (windowSize > 1200){
        numberOfElementsToShow = 13;
    }
    if (windowSize < 1250 && windowSize >= 1201) {
        numberOfElementsToShow = 12;
    }
    if (windowSize < 1200 && windowSize >= 1000) {
        numberOfElementsToShow = 10;
    }
    if (windowSize < 1000 && windowSize >= 769) {
        numberOfElementsToShow = 12;
    }
    if (windowSize < 769 && windowSize >= 500) {
        numberOfElementsToShow = 8;
    }
    if (windowSize < 500 && windowSize >= 400) {
        numberOfElementsToShow = 6;
    }
    if (windowSize < 400 && windowSize >= 375) {
        numberOfElementsToShow = 5;
    }
    if (windowSize < 374 && windowSize >= 300) {
        numberOfElementsToShow = 4;
    }
    if (windowSize < 300) {
        numberOfElementsToShow = 2;
    }
    if( numberOfElements > numberOfElementsToShow) {
        var arialabeltext = $('.loadMore').attr('aria-label');
        $('.loadMore').show();
        $('.color-swatchs-palette-container li:gt(' + (numberOfElementsToShow - 1) + ')').hide();
        $('.loadMore').children('.loadmoreText').empty().html('+' + (numberOfElements - numberOfElementsToShow));
        $('.loadMore').attr('aria-label','+ ' + (numberOfElements - numberOfElementsToShow)+ " " + arialabeltext);
    }

    $(document).on('click', '.loadMore', function () {
        $(this).attr('aria-pressed', false).hide();
        $('.showLess').show();
        $('.color-swatchs-palette').addClass('scrollMultipleShades');
        $('.showLess').attr('aria-pressed', true);

        // Order by full shade position
        orderByFullShadePosition();

        if (numberOfElements > numberOfElementsToShow) {
            $('.color-swatchs-palette-container li').show();
        } else{
            $('.shades-list-container li:last-child button').focus();
        }
    });

    $('.showLess').click(function () {
        // Order by default shade position
        orderByDefaultShadePosition();

        if (numberOfElements > numberOfElementsToShow) {
            $('.color-swatchs-palette-container li:gt(' + (numberOfElementsToShow - 1) + ')').hide();
        }
        $('.color-swatchs-palette').removeClass('scrollMultipleShades');
        $(this).attr('aria-pressed', false).hide();
        $('.loadMore').attr('aria-pressed', true).show();
        $('.loadMore').focus();
    }); 

    $('.shades-list-container li button').on('keydown', function (evt) {
        if (evt.keyCode === 37) {
            // Left arrow key
            $(this).attr('tabindex', '-1')
            $(evt.target)
                .parent()
                .prev()
                .find('button')
                .focus()
                .attr('tabindex', '0')
                .attr('aria-current', true)
                .click();
        } else if (evt.keyCode === 39) {
            // Right arrow key
            $(this).attr('tabindex', '-1');
            $(evt.target)
                .parent()
                .next()
                .find('button')
                .focus()
                .attr('tabindex', '0')
                .attr('aria-current', false)
                .click();
        }
    });
    
    // handle ADA button press
    $('.js-button-role').on('keydown', function (evt) {
        if (evt.key === ' ' || evt.key === 'Enter') {
            evt.preventDefault();
            $(this).click();
        }
    });
}


function orderByFullShadePosition() {
    $('.color-swatchs-palette-container .shades-list-container li').sort(function(a, b) {
        return +a.getAttribute('data-full-position') - +b.getAttribute('data-full-position');
    }).appendTo(".color-swatchs-palette-container .shades-list-container");
}

function orderByDefaultShadePosition() {
    $('.color-swatchs-palette-container .shades-list-container li').sort(function(a, b) {
        return +a.getAttribute('data-default-position') - +b.getAttribute('data-default-position');
    }).appendTo(".color-swatchs-palette-container .shades-list-container");
}

/**
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} productUrl - url to be used for going to the product details page
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 */
function fillModalElement(selectedValueUrl) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: selectedValueUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);
            $('.modal-body').empty();
            $('.modal-body').html(parsedHtml.body);
            $('.modal-footer').html(parsedHtml.footer);
            $('.full-pdp-link').text(data.quickViewFullDetailMsg);
            $('#quickViewModal .size-chart').attr('href', data.productUrl);
            $('#quickViewModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#quickViewModal .enter-message').text(data.enterDialogMessage);
            $('#quickViewModal').modal('show');
            $('body').trigger('quickview:ready');
            quantitySelector();
            shadeSelector();
            animateCarousel();
            $.spinner().stop();
            trapFocus($('#quickViewModal .modal-content')[0]);
            setTimeout(() => {
                $('#quickViewModal .modal-header .close').trigger('focus')
            }, 500);
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

module.exports = {
    showQuickview: function () {
        let clickedButton;
        $('body').on('click', '.quickview-btn', function (e) {
            e.preventDefault();
            clickedButton = $(this)
            var selectedValueUrl = clickedButton.attr('data-href');
            $(e.target).trigger('quickview:show');// here is the problem
            getModalHtmlElement();
            fillModalElement(selectedValueUrl);
        });
        $('body').on('click', '#quickViewModal .close', function (e) {
            e.preventDefault();
            clickedButton.trigger('focus')
        });
    },

    changeSize: function () {
        $('body').on('change', '.custom-capacity-menu-modify', function() {
            $('option:selected', this).trigger('click');
            $('.custom-capacity-menu-modify').val($(this).val());
        });
    },    
    
    showSpinner: function () {
        $('body').on('product:beforeAddToCart', function (e, data) {
            $(data).closest('.modal-content').spinner().start();
        });
    },
    hideDialog: function () {
        $('body').on('product:afterAddToCart', function () {
            $('#quickViewModal').modal('hide');
        });
    },
    beforeUpdateAttribute: function () {
        $('body').on('product:beforeAttributeSelect', function () {
            $('.modal.show .modal-content').spinner().start();
        });
    },
    updateAttribute: function () {
        $('body').on('product:afterAttributeSelect', function (e, response) {
            if ($('.modal.show .product-quickview>.bundle-items').length) {
                $('.modal.show').find(response.container).data('pid', response.data.product.id);
                $('.modal.show').find(response.container)
                    .find('.product-id').text(response.data.product.id);
            } else if ($('.set-items').length) {
                response.container.find('.product-id').text(response.data.product.id);
            } else {
                $('.modal.show .product-quickview').data('pid', response.data.product.id);
                $('.modal.show .full-pdp-link')
                    .attr('href', response.data.product.selectedProductUrl);
            }
        });
    },
    updateAddToCart: function () {
        $('body').on('product:updateAddToCart', function (e, response) {
            // update local add to cart (for sets)
            $('button.add-to-cart', response.$productContainer).attr('disabled',
                (!response.product.readyToOrder || !response.product.available));

            // update global add to cart (single products, bundles)
            var dialog = $(response.$productContainer)
                .closest('.quick-view-dialog');

            $('.add-to-cart-global', dialog).attr('disabled',
                !$('.global-availability', dialog).data('ready-to-order')
                || !$('.global-availability', dialog).data('available')
            );
        });
    },
    updateAvailability: function () {
        $('body').on('product:updateAvailability', function (e, response) {
            // bundle individual products
            $('.product-availability', response.$productContainer)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);


            var dialog = $(response.$productContainer)
                .closest('.quick-view-dialog');

            if ($('.product-availability', dialog).length) {
                // bundle all products
                var allAvailable = $('.product-availability', dialog).toArray()
                    .every(function (item) { return $(item).data('available'); });

                var allReady = $('.product-availability', dialog).toArray()
                    .every(function (item) { return $(item).data('ready-to-order'); });

                $('.global-availability', dialog)
                    .data('ready-to-order', allReady)
                    .data('available', allAvailable);

                $('.global-availability .availability-msg', dialog).empty()
                    .html(allReady ? response.message : response.resources.info_selectforstock);
            } else {
                // single product
                $('.global-availability', dialog)
                    .data('ready-to-order', response.product.readyToOrder)
                    .data('available', response.product.available)
                    .find('.availability-msg')
                    .empty()
                    .html(response.message);
            }
        });
    }
};
