'use strict';
function detectLastFocusableElement(elemList) {
    for (var i = elemList.length - 1; i >= 0; i--) {
        if (elemList[i].offsetWidth > 0 && elemList[i].offsetHeight > 0)
            return elemList[i];
    }
}

function detectFirstFocusableElement(elemList) {
    var firstEl;
    elemList.forEach(function(item) {
        if (item.offsetWidth > 0 && item.offsetHeight > 0 && !firstEl) {
            firstEl = item;
        }
    })
    return firstEl;
}

var util = {
    isMobile: function () {
        var mobileAgentHash = ['mobile', 'tablet', 'phone', 'ipad', 'ipod', 'android', 'blackberry', 'windows ce', 'opera mini', 'palm'];
        var idx = 0;
        var isMobile = false;
        var userAgent = (navigator.userAgent).toLowerCase();

        while (mobileAgentHash[idx] && !isMobile) {
            isMobile = (userAgent.indexOf(mobileAgentHash[idx]) >= 0);
            idx++;
        }
        return isMobile;
    },
    isTablet: function () {
        return this.isMobile() || window.innerWidth < 1024;
    },
    trapFocus: function(element) {
        var focusableEls = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]):not([tabindex="-1"]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
        var firstFocusableEl = detectFirstFocusableElement(focusableEls);  
        var lastFocusableEl = detectLastFocusableElement(focusableEls);
        var KEYCODE_TAB = 9;

        element.addEventListener('keydown', function(e) {
            var isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);

            if (!isTabPressed) { 
                return; 
            }

            if ( e.shiftKey ) /* shift + tab */ {
                if (document.activeElement === firstFocusableEl) {
                    lastFocusableEl.focus();
                    e.preventDefault();
                }
            } else /* tab */ {
                focusableEls = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]):not([tabindex="-1"]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
                firstFocusableEl = detectFirstFocusableElement(focusableEls);
                lastFocusableEl = detectLastFocusableElement(focusableEls);
                if (document.activeElement === lastFocusableEl) {
                    firstFocusableEl.focus();
                    e.preventDefault();
                }

            }
        });
    }
};

module.exports = util;